import React, { useState } from 'react'
import Title from '../common/title/Title'
import { faq } from '../../dummydata'

const Feq = () => {

    const [click,setClick] = useState(false)
    const toggle = (index) => {
        if(click === index){
            return setClick(null)
        }
        setClick(index)
    }

  return (
    <>
        <Title subtitle='DÚVIDAS?' title='DÚVIDAS FREQUENTES QUE RECEBEMOS' />
        <section className='faq'>
            <div className="container">
                {faq.map((val, index)=> (
                    <div key={index} className="box">
                        <button className='accordion' onClick={() => toggle(index)} key={index}>
                            <h2>{val.title}</h2>
                            <span>{click === index ? <i className='fa fa-chevron-down'></i> : <i className='fa fa-chevron-down'></i>}</span>
                        </button>
                        {click === index ? (
                            <div className="text">
                            <p>{val.desc}</p>
                        </div>
                        ) : null}
                    </div>
                ))}
            </div>
        </section>
    </>
  )
}

export default Feq
