import React from 'react'
import Back from '../common/back/Back'
import AboutCard from './AboutCard'

const About = () => {
  return (
    <>
      <Back title='Sobre nós'/>
      <AboutCard />
    </>
  )
}

export default About
