import React from 'react'
import Title from '../common/title/Title'
import { homeAbout } from '../../dummydata'
import AWrapper from './AWrapper'
import './about.css'


const AboutCard = () => {
  return (
    <>
      <section className='aboutHome'>
        <div className="container flexSB">
            <div className="left row">
                <img src="./images/logostenico.png" alt="logoempresa" />
            </div>
            <div className="right row">
                <Title subtitle="TRANSFORMAMOS SUA IDEIA EM REALIDADE" title="SOBRE NOSSA EMPRESA E VALORES" />
                <div className="items">
                    {homeAbout.map((val) => (
                        <div key={val.id} className="item flexSB">
                            <div className="img">
                                <img src={val.cover} alt="cover" />
                            </div>
                            <div className="text">
                                <h2>{val.title}</h2>
                                <p>{val.desc}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
      </section>
      <AWrapper />
    </>
  )
}

export default AboutCard
