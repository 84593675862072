import React from 'react'
import './hero.css'
import Title from '../../common/title/Title'

const Hero = () => {
  return (
    <>
      <section className="hero">
        <div className="container">
            <div className="row">
                <Title subtitle="SOLUÇÕES CRIATIVAS PARA COMUNICAÇÃO VISUAL QUE IMPRESSIONAM E CONECTAM" title="TRANSFORMANDO IDEIAS EM IMAGENS"/>
                <p>Nossa empresa de comunicação visual oferece soluções personalizadas e de alta qualidade, combinando tecnologia avançada e uma equipe 
                  capacitada para garantir designs impactantes e eficientes, que destacam nossos clientes no mercado.</p>
            <div className="button">
                <button className="primary-btn">
                    EXPLORE NOSSO SITE <i className='fa fa-long-arrow-alt-right'></i>
                </button>
                <button>
                    ENTRE EM CONTATO <i className='fa fa-long-arrow-alt-right'></i>
                </button>
            </div>
            </div>
        </div>
      </section>
      <div className="margin"></div>
    </>
  )
}

export default Hero
