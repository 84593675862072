import React, { useRef } from 'react';
import './contact.css'
import Back from '../common/back/Back'
import emailjs from '@emailjs/browser';

const Contact = () => {
  const map = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.073974798989!2d-47.44046478928518!3d-23.493844859026712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf60170afa35ab%3A0x83e0ce70aaac4670!2sComunica%C3%A7%C3%A3o%20Visual%20Stenico!5e0!3m2!1spt-BR!2sbr!4v1711809480367!5m2!1spt-BR!2sbr'
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    

    emailjs
      .sendForm('service_6lhj5fr', 'template_irobekc', form.current, {
        publicKey: '_evBl8egWPqKbH0Jl',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
      e.target.reset()
  };
  return (
    <>
      <Back title='Contato' />
      <section className='contacts padding'>
        <div className='container shadow flexSB'>
          <div className='left row'>
            <iframe src={map}></iframe>
          </div>
          <div className='right row'>
            <h1>Entre em contato</h1>
            <p>Nos mande sua dúvida por email ou se desejar realizar um orçamento!</p>

            <div className='items grid2'>
              <div className='box'>
                <h4>ENDEREÇO:</h4>
                <p>R. João Ferreira da Silva, 1319 - Além Ponte, Sorocaba - SP, 18013-200</p>
              </div>
              <div className='box'>
                <h4>EMAIL:</h4>
                <p>contato@stenico.com.br</p>
              </div>
              <div className='box'>
                <h4>TELEFONE:</h4>
                <p>15-3237 3498</p>
              </div>
              <div className='box'>
                <h4>WHATSAPP:</h4>
                <p>15-99701.5189</p>
              </div>
            </div>
          
            <form ref={form} onSubmit={sendEmail}>
              <div className='flexSB'>
                <input type='text'name="user_name" placeholder='Nome' />
                <input type='email' name="user_email" placeholder='Email' />
              </div>
              <input type='text' name="subjact" placeholder='Titulo da mensagem' />
              <textarea cols='30' rows='10' name="message" placeholder='Escreva para nós aqui...' />
              <button type="submit" value="Send" className='primary-btn'>ENVIAR</button>
            </form>

            <h3>Siga nossas redes sociais!</h3>
            <a href="https://api.whatsapp.com/send?phone=5515997015189&text=Olá,%20tenho%20interesse%20em%20seus%20produtosl!"><span>WHATSAPP </span></a>
            <a href="https://www.instagram.com/_stenico/"><span>INSTAGRAM</span></a>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
