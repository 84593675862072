import React from 'react'
import Title from '../common/title/Title'
import { poupular } from '../../dummydata'

const PoupularServices = () => {
  return (
    <>
      <section className='poupular'>
        <div className="container">
            <Title subtitle='TODOS NOSSOS SERVIÇOS' title='ESCOLHA O SERVIÇO DA SUA NECESSIDADE E ENTRE EM CONTATO!' />
            <a href="https://api.whatsapp.com/send?phone=5515997015189&text=Olá,%20tenho%20interesse%20em%20seus%20produtosl!">
            <div className="content grid3">
                {poupular.map((val, index) => (
                        <div key={index} className="box">
                        <div className="img">
                            <img src={val.cover} alt="cover" />
                            <img src={val.hoverCover} alt="houvercover" className='show' />
                        </div>
                        <h1>{val.serviceName}</h1>
                        <span>{val.service}</span>
                    </div>
                ))}
            </div>
            </a>
        </div>
      </section>
    </>
  )
}

export default PoupularServices
