import React from 'react'
import { servicesCard } from '../../dummydata'
import './services.css'

const ServicesCard = () => {
return (
    <>
    <section className='servicesCard'>
        <div className="container grid2">
            {servicesCard.map((val, index) =>{
                if ( val.id === 2 || val.id === 3 || val.id === 5 || val.id === 6 || val.id === 7 || val.id === 8) {
                return (
                <div key={val.id} className="items">
                    <div className="content flex">
                        <div className="left">
                            <div key={index} className="img">
                                <img src={val.cover} alt="cover" />
                            </div>
                        </div>
                        <div key={index} className="text">
                            <h1>{val.serviceName}</h1>
                            <div className="rate">
                                <i className='fa fa-star'></i>
                                <i className='fa fa-star'></i>
                                <i className='fa fa-star'></i>
                                <i className='fa fa-star'></i>
                                <i className='fa fa-star'></i>
                                <label htmlFor="">(5.0)</label>
                            </div>
                        </div>
                    </div>
                    <a href="https://api.whatsapp.com/send?phone=5515997015189&text=Olá,%20tenho%20interesse%20em%20seus%20produtosl!">
                    <button className='outline-btn'>FAÇA O ORÇAMENTO AGORA!</button>
                    </a>
                </div>
                )
            } else {
                return ( <div key={val.id} className="itemsGrande">
                <div className="content flex">
                <div className="left">
                    <div key={index} className="img">
                    <img src={val.cover} alt="cover" />
                    </div>
                </div>
                <div key={index} className="text">
                    <h1>{val.serviceName}</h1>
                    <div className="rate">
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <label htmlFor="">(5.0)</label>
                    </div>
                </div>
                </div>
                <a href="https://api.whatsapp.com/send?phone=5515997015189&text=Olá,%20tenho%20interesse%20em%20seus%20produtosl!">
                <button className="outline-btn">
                    FAÇA O ORÇAMENTO AGORA!
                </button>
                </a>
            </div>); // Se o ID não for 1, 2 ou 3, não renderize nada
            }
            })}
        </div>
    </section>
    </>
    )
}

export default ServicesCard
