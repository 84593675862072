import React from 'react'
import Back from '../common/back/Back'
import ServicesCard from './ServicesCard'
import PoupularServices from './PoupularServices'
import Feq from './Feq'

const ServiceHome = () => {
  return (
    <>
        <Back title='Serviços' />
        <ServicesCard />
        <PoupularServices />
        <Feq />
    </>
  )
}

export default ServiceHome
