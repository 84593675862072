import React from "react";

const Head = () => {
  return (
    <>
      <section className="head">
        <div className="container flexSB">
          <div className="logo">
            <h1>STENICO</h1>
            <span>COMUNICAÇÃO VISUAL</span>
          </div>

          <div className="social">
            <a href="https://www.instagram.com/_stenico/"><i className="fab fa-instagram icon" /></a>
            <a href="https://api.whatsapp.com/send?phone=5515997015189&text=Olá,%20tenho%20interesse%20em%20seus%20produtosl!"><i className="fab fa-whatsapp icon" /></a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Head;
