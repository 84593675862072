import "./App.css";
import Header from "./components/common/heading/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/home/Home";
import About from "./components/about/About";
import ServiceHome from "./components/allservices/ServiceHome";
import Contact from "./components/contact/Contact";
import Footer from "./components/common/footer/Footer";

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/empresa" exact component={About} />
          <Route path="/servicos" exact component={ServiceHome} />
          <Route path="/contato" exact component={Contact} />
        </Switch>
        <Footer />
      </Router>

      <div className="whatsappButtom">
        <a href="https://api.whatsapp.com/send?phone=5515997015189&text=Olá,%20tenho%20interesse%20em%20seus%20produtosl!" target="_blank"  rel="noopener noreferrer">
          <img
            width="48"
            height="48"
            src="https://img.icons8.com/color/48/whatsapp--v1.png"
            alt="whatsapp--v1"
          />
        </a>
      </div>
    </>
  );
};

export default App;
