export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: "Quem nós somos?",
    desc: "Nossa empresa é especializada na confecção de produtos e serviços visando sempre que sua empresa apareça no mercado.",
  },
  {
    id: 2,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: "Assessoria de qualidade",
    desc: "Nós ajudamos a criar um design atraente para sua empresa, utilizando as melhores fontes, cores e imagens para destacá-la no mercado.",
  },
  {
    id: 3,
    cover: "https://img.icons8.com/ios/50/rocket--v1.png",
    title: "Missão, visão e valores",
    desc: "Nossa missão é oferecer soluções de identidade visual de qualidade, ágeis e personalizadas, com tecnologia avançada e profissionais capacitados.",
  },
];
export const awrapper = [
  {
    cover:"https://img.icons8.com/external-outline-wichaiwi/64/FFFFFF/external-best-key-performance-indicators-outline-wichaiwi.png",
    data: "+1.000",
    title: "SUCESSO DE VENDAS",
  },

  {
    cover: "https://img.icons8.com/ios/64/FFFFFF/services--v1.png",
    data: "+15",
    title: "TIPOS DE SERVIÇO",
  },
  {
    cover:"https://img.icons8.com/wired/64/FFFFFF/guarantee--v1.png",
    data: "100%",
    title: "CONFIANÇA NA QUALIDADE",
  },
  {
    cover: "https://img.icons8.com/dotty/80/FFFFFF/briefcase-settings.png",
    data: "+15 ANOS",
    title: "EXPERIENCIA NO MERCADO",
  },
];
export const servicesCard = [
  {
    id: 1,
    cover: "https://img.icons8.com/carbon-copy/512/FFFFFF/bank-cards.png",
    serviceName: "Cartões de Visita",
    serviceProvider: [
      {
        dcover: "./images/logostenico.png",
        name: "Feito por Renato",
      },
    ],
    priceAll: "Consulte sobre instalação",
  },
  {
    id: 2,
    cover: "https://img.icons8.com/external-xnimrodx-lineal-xnimrodx/512/FFFFFF/external-banner-infographic-and-chart-xnimrodx-lineal-xnimrodx-2.png",
    serviceName: "Banners",
    serviceProvider: [
      {
        dcover: "./images/logostenico.png",
        name: "Feito por Flavio",
      },
    ],
    priceAll: "Consulte sobre instalação",
  },
  {
    id: 3,
    cover: "https://img.icons8.com/external-icongeek26-outline-icongeek26/512/FFFFFF/external-Stickers-printing-icongeek26-outline-icongeek26-2.png",
    serviceName: "Adesivos",
    serviceProvider: [
      {
        dcover: "./images/logostenico.png",
        name: "Feito por Flavio",
      },
    ],
    priceAll: "Consulte sobre instalação",
  },
  {
    id: 4,
    cover: "https://img.icons8.com/ios/512/FFFFFF/car--v1.png",
    serviceName: "Personalização de Veículos",
    serviceProvider: [
      {
        dcover: "./images/logostenico.png",
        name: "Feito por Flavio",
      },
    ],
    priceAll: "Consulte sobre instalação",
  },
  {
    id: 5,
    cover: "https://img.icons8.com/external-vitaliy-gorbachev-lineal-vitaly-gorbachev/512/FFFFFF/external-banners-protest-vitaliy-gorbachev-lineal-vitaly-gorbachev.png",
    serviceName: "Placas",
    serviceProvider: [
      {
        dcover: "./images/logostenico.png",
        name: "Feito por Flavio",
      },
    ],
    priceAll: "Consulte sobre instalação",
  },
  {
    id: 6,
    cover: "https://img.icons8.com/external-prettycons-lineal-prettycons/512/FFFFFF/external-marketing-multimedia-prettycons-lineal-prettycons.png",
    serviceName: "Cavaletes",
    serviceProvider: [
      {
        dcover: "./images/logostenico.png",
        name: "Feito por Flavio",
      },
    ],
    priceAll: "Consulte sobre instalação",
  },
  {
    id: 7,
    cover: "https://img.icons8.com/external-others-pike-picture/512/FFFFFF/external-Flyers-shopping-others-pike-picture-2.png",
    serviceName: "Panfletos",
    serviceProvider: [
      {
        dcover: "./images/logostenico.png",
        name: "Feito por Renato",
      },
    ],
    priceAll: "Consulte sobre instalação",
  },
  {
    id: 8,
    cover: "https://img.icons8.com/external-outlines-amoghdesign/512/FFFFFF/external-banner-thanksgiving-day-outlines-amoghdesign.png",
    serviceName: "Faixas",
    serviceProvider: [
      {
        dcover: "./images/logostenico.png",
        name: "Feito por Flavio",
      },
    ],
    priceAll: "Consulte sobre instalação",
  },
  {
    id: 9,
    cover: "https://img.icons8.com/ios/512/FFFFFF/calendar--v1.png",
    serviceName: "Calendario de Mesa",
    serviceProvider: [
      {
        dcover: "./images/logostenico.png",
        name: "Feito por Renato",
      },
    ],
    priceAll: "Consulte sobre instalação",
  },
];
export const poupular = [
  {
    cover: "https://img.icons8.com/external-outlines-amoghdesign/512/1eb2a6/external-banner-thanksgiving-day-outlines-amoghdesign.png",
    hoverCover: "https://img.icons8.com/external-outlines-amoghdesign/512/FFFFFF/external-banner-thanksgiving-day-outlines-amoghdesign.png",
    serviceName: "Faixas",
    service: "Consulte",
  },
  {
    cover: "https://img.icons8.com/external-xnimrodx-lineal-xnimrodx/512/1eb2a6/external-banner-infographic-and-chart-xnimrodx-lineal-xnimrodx-2.png",
    hoverCover: "https://img.icons8.com/external-xnimrodx-lineal-xnimrodx/512/FFFFFF/external-banner-infographic-and-chart-xnimrodx-lineal-xnimrodx-2.png",
    serviceName: "Banners",
    service: "Consulte",
  },
  {
    cover: "https://img.icons8.com/external-icongeek26-outline-icongeek26/512/1eb2a6/external-Stickers-printing-icongeek26-outline-icongeek26-2.png",
    hoverCover: "https://img.icons8.com/external-icongeek26-outline-icongeek26/512/FFFFFF/external-Stickers-printing-icongeek26-outline-icongeek26-2.png",
    serviceName: "Adesivos",
    service: "Consulte",
  },
  {
    cover: "https://img.icons8.com/external-simple-solid-edt.graphics/512/1eb2a6/external-flag-flags-simple-solid-edt.graphics-16.png",
    hoverCover: "https://img.icons8.com/external-simple-solid-edt.graphics/512/FFFFFF/external-flag-flags-simple-solid-edt.graphics-16.png",
    serviceName: "Wind Banner",
    service: "Consulte",
  },
  {
    cover: "https://img.icons8.com/external-vitaliy-gorbachev-lineal-vitaly-gorbachev/512/1eb2a6/external-banners-protest-vitaliy-gorbachev-lineal-vitaly-gorbachev.png",
    hoverCover: "https://img.icons8.com/external-vitaliy-gorbachev-lineal-vitaly-gorbachev/512/FFFFFF/external-banners-protest-vitaliy-gorbachev-lineal-vitaly-gorbachev.png",
    serviceName: "Placas",
    service: "Consulte",
  },
  {
    cover: "https://img.icons8.com/external-prettycons-lineal-prettycons/512/1eb2a6/external-marketing-multimedia-prettycons-lineal-prettycons.png",
    hoverCover: "https://img.icons8.com/external-prettycons-lineal-prettycons/512/FFFFFF/external-marketing-multimedia-prettycons-lineal-prettycons.png",
    serviceName: "Cavaletes",
    service: "Consulte",
  },
  {
    cover: "https://img.icons8.com/external-others-pike-picture/512/1eb2a6/external-Flyers-shopping-others-pike-picture-2.png",
    hoverCover: "https://img.icons8.com/external-others-pike-picture/512/FFFFFF/external-Flyers-shopping-others-pike-picture-2.png",
    serviceName: "Panfletos",
    service: "Consulte",
  },
  {
    cover: "https://img.icons8.com/carbon-copy/512/1eb2a6/bank-cards.png",
    hoverCover: "https://img.icons8.com/carbon-copy/512/FFFFFF/bank-cards.png",
    serviceName: "Cartões de Visita",
    service: "Consulte",
  },
  {
    cover: "https://img.icons8.com/ios/512/1eb2a6/calendar--v1.png",
    hoverCover: "https://img.icons8.com/ios/512/FFFFFF/calendar--v1.png",
    serviceName: "Calendario de Mesa",
    service: "Consulte",
  },
  {
    cover: "https://img.icons8.com/carbon-copy/512/1eb2a6/paper.png",
    hoverCover: "https://img.icons8.com/carbon-copy/512/FFFFFF/paper.png",
    serviceName: "Folhinha",
    service: "Consulte",
  },
  {
    cover: "https://img.icons8.com/external-tanah-basah-basic-outline-tanah-basah/512/1eb2a6/external-magnet-strategy-tanah-basah-basic-outline-tanah-basah.png",
    hoverCover: "https://img.icons8.com/external-tanah-basah-basic-outline-tanah-basah/512/FFFFFF/external-magnet-strategy-tanah-basah-basic-outline-tanah-basah.png",
    serviceName: "Imãs de Geladeira",
    service: "Consulte",
  },
  {
    cover: "https://img.icons8.com/dotty/512/1eb2a6/tags.png",
    hoverCover: "https://img.icons8.com/dotty/512/FFFFFF/tags.png",
    serviceName: "Tags",
    service: "Consulte",
  },
  {
    cover: "https://img.icons8.com/external-others-zufarizal-robiyanto/512/1eb2a6/external-carpet-garis-ramadan-others-zufarizal-robiyanto.png",
    hoverCover: "https://img.icons8.com/external-others-zufarizal-robiyanto/512/FFFFFF/external-carpet-garis-ramadan-others-zufarizal-robiyanto.png",
    serviceName: "Tapetes para Lava Rápido",
    service: "Consulte",
  },
  {
    cover: "https://img.icons8.com/ios/512/1eb2a6/check-book.png",
    hoverCover: "https://img.icons8.com/ios/512/FFFFFF/check-book.png",
    serviceName: "Talão Simples",
    service: "Consulte",
  },
  {
    cover: "https://img.icons8.com/dotty/512/1eb2a6/check-book.png",
    hoverCover: "https://img.icons8.com/dotty/512/FFFFFF/check-book.png",
    serviceName: "Talão Autocopiativo",
    service: "Consulte",
  },
  {
    cover: "https://img.icons8.com/ios/512/1eb2a6/car--v1.png",
    hoverCover: "https://img.icons8.com/ios/512/FFFFFF/car--v1.png",
    serviceName: "Personalização de Veículos",
    service: "Consulte",
  }
];
export const faq = [
  {
    title: "Vocês oferecem serviços de impressão de grande formato para banners, adesivos e faixas?",
    desc: "Sim, oferecemos serviços de impressão de grande formato para uma variedade de materiais de qualidade.",
  },
  {
    title: "Qual é o prazo de entrega padrão para os projetos de comunicação visual?",
    desc: "Nosso prazo de entrega padrão varia dependendo da complexidade do projeto e da quantidade solicitada, mas geralmente trabalhamos para garantir entregas rápidas e eficientes.",
  },
  {
    title: "Vocês realizam a instalação dos materiais impressos, como adesivos em fachadas ou banners em eventos?",
    desc: "Consulte sobre instalação.",
  },
  {
    title: "Vocês fornecem serviços de design gráfico para criar a identidade visual de minha empresa? ",
    desc: "Sim, oferecemos serviços de design gráfico para criar uma imagem profissional e impactante para sua empresa.",
  },
  {
    title: "É possível obter um orçamento personalizado para o meu projeto de comunicação visual?",
    desc: "Sim, estamos abertos a negociações e podemos fornecer orçamentos personalizados de acordo com suas necessidades específicas.",
  },
  {
    title: "Se eu não tiver um conceito definido para meu projeto, vocês oferecem consultoria ou sugestões de design?",
    desc: "Sim, nossa equipe está disponível para fornecer consultoria e sugestões de design para ajudá-lo a criar projetos que atendam às suas expectativas e objetivos de marketing.",
  },
];
export const testimonal = [
  {
    id: 1,
    name: "VALDIR JOSE",
    post: "CLIENTE HÁ TRÊS ANOS",
    desc: "Ótimo atendimento e retorno rápido das solicitações que fizemos.",
    cover: "https://img.icons8.com/ios-filled/100/user-male-circle.png",
  },
  {
    id: 2,
    name: "FABIO CARDOSO",
    post: "CLIENTE HÁ DOIS ANOS",
    desc: "Excelente gráfica. Sempre que precisei de rótulos e adesivos fui muito bem atendido. Recomendo.",
    cover: "https://img.icons8.com/ios-filled/100/user-male-circle.png",
  },
  {
    id: 3,
    name: "ZENDONÁ",
    post: "CLIENTE HÁ TRÊS ANOS",
    desc: "Atendimento profissional, qualidade nos produtos e um ótimo valor que foi negociavel.",
    cover: "https://img.icons8.com/ios-filled/100/user-male-circle.png",
  },
];
