import React from "react";
import "./footer.css";
import { Link } from "react-router-dom"


const Footer = () => {
  return (
    <>
      <footer>
        <div className="container padding">
          <div className="box logo">
            <h1>STENICO</h1>
            <span>COMUNICAÇÃO VISUAL</span>
            <p>
            Siga nossas redes sociais para ficar ligado nas nossas atualizações!
            </p>
            <a href="https://api.whatsapp.com/send?phone=5515997015189&text=Olá,%20tenho%20interesse%20em%20seus%20produtosl!"><i className="fab fa-whatsapp icon" /></a>
            <a href="https://www.instagram.com/_stenico/"><i className="fab fa-instagram icon"></i></a>
          </div>
          <div className="box link">
            <h3>Explore</h3>
            <ul>
              <li><Link to='/empresa'>Empresa</Link></li>
              <li><Link to='/servicos'>Serviços</Link></li>
              <li><Link to='/contato'>Contato</Link></li>
            </ul>
          </div>
          <div className="box link">
            <h3>Links Rápidos</h3>
            <ul>
              <li><Link to='/contato'>Contato</Link></li>
              <li><Link to='/servicos'>Preços</Link></li>
              <li><Link to='/'>Feedbacks</Link></li>
            </ul>
          </div>
          <div className="box last">
            <h3>Alguma Pergunta?</h3>
            <ul>
              <li>
                <i className="fa fa-map"></i>
                João Ferreira da Silva, 1319 - Além Ponte
              </li>
              <li>
                <i className="fa fa-phone-alt"></i>
                15-3237 3498
              </li>
              <li>
                <i className="fa fa-phone-alt"></i>
                15-99701 5189
              </li>
              <li>
                <i className="fa fa-paper-plane"></i>
                contato@stenico.com.br
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className="legal">
        <p>
          Copyright ©2024 Todos os direitos reservados | Esse site foi feito por {" "}
          <i className="fa-solid fa-gear"></i> by Rafael Augusto
        </p>
      </div>
    </>
  );
};

export default Footer;
